html {
  font-size: 62.5%;
  font-family: SFProDisplay-regular;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.liveStreamLeaveModal {
  position: absolute;
  top: 50%;
  left: 30%;
}

.liveStram-video-paused-infobox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
}

.liveStreamQuitModal {
  height: 100% !important;
  justify-content: center !important;
}

:root {
  --main-primary-color: #fff;
  --hover-color: #fff;
  --hover-secondary-color: #fff;
}

.full-width {
  width: 100%;
}

.MuiDialog-paperScrollBody {
  height: auto !important;
}

.MuiAppBar-colorPrimary {
  background-color: var(--bar-color) !important;
}

.makeTextClickable {
  cursor: pointer !important;
}

.termsIcon {
  font-size: 2.5rem !important;
  color: var(--main-primary-color);
}

.bg-white {
  background-color: #fff;
}

.bg-gray {
  background-color: #efeff4 !important;
}

.bg-red {
  background-color: #ff3b30 !important;
}

.mobileScrollParent {
  width: inherit !important;
}

.staticBlock.scroll {
  position: fixed;
  top: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  background: #fff;
  z-index: 999;
  width: 100%;
  left: 0;
}
.staticBlockEw.scroll {
  position: fixed;
  top: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: #fff;
  z-index: 999;
  width: 100%;
  left: 0;
}
.scroll .title,
.scroll .description {
  display: none;
}
.scroll .topKeySpace .p-t-32 {
  padding-top: 20px !important;
}
/* .scroll .browseCategory .makeTextClickable{
  font-size:28px !important;
} */
.browseCategory .MuiTab-root {
  max-width: 300px;
}
// Note: this class is for IOS scroll fix
.browseCategory .MuiTabs-flexContainer {
  max-width: 350px;
}
.liveStreamChips .MuiChip-root {
  background-color: #ffffff;
  color: var(--main-primary-color);
  margin: 5px 4px;
  height: 28px;
  min-width: 96px;
  cursor: pointer;
}

.liveStreamChips .MuiChip-clickable:hover,
.liveStreamChips .MuiChip-clickable:focus {
  background-color: #fff;
}
.MuiChip-clickableColorSecondary:hover,
.MuiChip-clickableColorSecondary:focus {
  color: #fff;
}

.liveStreamChips .MuiChip-clickable.active {
  color: #000000 !important;
  background-color: #fff;
}

.liveStreamChips .MuiChip-label {
  font-size: 15px;
  line-height: 1.47;
  font-family: "SFProRounded-Regular";
}
.chipUl {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.chipUl .MuiChip-label {
  font-family: SFProDisplay-Regular;
  font-size: 15px;
}
.myAccordion .chipUl .MuiChip-label {
  font-size: 13px;
}
.bg-green,
.btn-default,
.MuiButton-contained.Mui-disabled {
  background-color: var(--main-primary-color) !important;
}
.featured-labelNew {
  background-color: var(--main-secondary-color) !important;
}
.myAccordion.MuiAccordion-root:before {
  display: none;
}
.myAccordion .MuiAccordionSummary-root.Mui-expanded,
.myAccordion .MuiAccordionSummary-root {
  padding: 0;
  max-height: 46px !important;
  min-height: auto;
}
.myAccordion.MuiAccordion-rounded:last-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.myAccordion .MuiAccordionSummary-content.Mui-expanded,
.myAccordion .MuiAccordionSummary-content {
  margin: 10px 0 7px;
  height: 27px;
}
.myAccordion .MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(45deg);
}
.myAccordion .MuiIconButton-edgeEnd {
  margin-right: 0px;
}
.filter-text .MuiSvgIcon-root {
  height: 24px;
  width: 24px;
}
.bgContainer,
.bgContainer2::before,
.bgContainer3::before {
  background-color: var(--bg-theme-color);
}

video,
img,
.video-js {
  border-radius: 8px;
  /* border-top-left-radius: 8px;
  border-top-right-radius: 8px; */
}
.squareImageContainer img,
.live video,
.live img {
  border-radius: 8px;
}

.btn-default:hover {
  background-color: var(--hover-color) !important;
}
.filter-btn svg {
  vertical-align: sub;
  position: absolute;
  right: 16px;
}
.font-proDisplayBold {
  font-family: "SFProDisplay-SemiBold" !important;
  font-size: 17px !important;
  text-align: left;
  margin-bottom: 22px !important;
  .value {
    font-family: "SFProDisplay-SemiBold" !important;
    font-size: 13px !important;
    color: #8e8e93;
    margin-left: 10px;
  }
}
p.filter-text,
.filter-text .MuiListItemText-primary,
.filter-text .MuiInputBase-input {
  font-family: SFProDisplay-Regular;
  font-size: 15px;
  font-weight: normal;
}
.filter-text .MuiInputBase-input::placeholder,
.filter-text .MuiInputBase-input::-ms-input-placeholder {
  color: #8e8e93 !important;
}
.filter-text
  .MuiInput-underline.MuiInput-underline.MuiInput-underline.MuiInput-underline:before {
  border-bottom: none;
}
.filter-chip.MuiChip-outlined {
  border: solid 1px var(--main-primary-color);
  background-color: #ffffff;
  width: 96px;
  height: 28px;
  font-size: 15px;
  font-family: "SFProDisplay-Semibold";
  margin-right: 10px;
}
.myAccordion.MuiAccordion-rounded {
  border-bottom: 1px solid #d1d1d6;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
.CollectionHome .adjustTitleWidth,
.CollectionHome svg.iconColor {
  display: none;
}
/* ===================== Channels styling ================== */

.banner-overlay {
  height: calc(100% - 4px) !important;
  top: 0;
  border-radius: 0 !important;
  aspect-ratio: auto;
}

.bg-banner {
  width: 100%;
  border-radius: 0;
}
.channelbg-banner {
  width: 100%;
  /* background: url(../images/geybg.jpg) repeat; */
}
.channels-subhead {
  font-family: SFProDisplay-Semibold !important;
}

.collection p.MuiTypography-body1 {
  font-size: 17px !important;
  line-height: 1.76;
  letter-spacing: -0.15px;
}

.channel-desc {
  width: 100%;
  height: 48px;
  font-family: SFProDisplay-Regular;
  font-size: 15px;
  letter-spacing: normal;
  line-height: 1.6 !important;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  box-orient: vertical;
  -webkit-box-orient: vertical;
  /* autoprefixer: off */
}

.pointer-cursor {
  cursor: pointer;
}
/* ========================================================= */

.linkFontSize {
  font-size: 17px !important;
  font-family: SFProDisplay-regular;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: -0.3px;
}

.selectButtonText {
  font-family: SFProRounded;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.selectButton {
  width: 132px;
  height: 28px;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0px solid;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

.bgContainer {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.bgContainer::before {
  background-color: #fff;
  top: 102%;
  content: "";
  display: block;
  height: 92%;
  position: absolute;
  right: 0;
  transform-origin: 58% 18%;
  transform: rotate(180deg);
  width: 150%;
}

.bgContainer2::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100px;
  top: 10%;
}

.bgContainer2.top0::before {
  top: 0 !important;
}

.bgContainer2 {
  background: #fff;
  /* min-height: 300px; */
  /* overflow: hidden; */
  position: relative;
  width: 100%;
}

.nowrap {
  max-width: 85%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.cotd2 {
  text-align: right;
  object-fit: cover;
  height: 158px !important;
  &.w-256 {
    display: flex;
    justify-content: flex-end;
    // .Trailer{
    //   max-width: 256px !important;
    //   max-height: 144px !important;
    // }
  }
}
.cotd2 h3 {
  font-size: 15px;
}
.cotd2 .Trailer {
  // max-width: 256px;
  object-fit: fill;
}
.codBg .MuiTypography-subtitle2 {
  font-size: 22px !important;
  font-family: SFProDisplay-Bold !important;
  line-height: 1.36;
}
.cotd2 .workout-footer {
  margin-left: 35px !important;
  color: #000000 !important;
  margin-top: -18px;
}
.workout-footer-p.nowrap {
  max-width: 80%;
}
/* .cotd .workoutPlayer .Trailer {
  height: 395px;
} */
.noWidth {
  width: auto !important;
  min-width: auto !important;
}

.noHeight {
  height: auto !important;
}

.MuiTabs-root {
  min-height: auto !important;
}

.fa {
  margin: 8px;
}

.deniedAccess {
  padding: 10px;
  border: solid 1px #d61c00;
}

.AppBarButtonMobile {
  font-size: 10px;
  width: 90px;
  height: 30px;
  color: #fff;
  border: none;
}

.loginButtonHolder {
  height: inherit;
  // .item-search{
  //   height: 28px;
  // }
  .icon-search {
    height: 28px;
    margin-right: 1em;
    cursor: pointer;
  }
}

.boxShadow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.AccountBarButton {
  font-size: 10px;
  width: 142px;
  height: 32px;
  color: #fff;
  border: none;
}

.Clear-filters {
  height: 16px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  display: flex;
  justify-content: flex-start;
  padding-left: 12px;
  cursor: pointer;
  margin-bottom: 28px;
}

.No-results-found {
  height: 16px;
  font-size: 14px;
  font-weight: bold !important;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3c3c3b;
  margin-top: 30px;
}

.signUpButton {
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.33px;
  text-align: center;
  color: #ffffff;
  width: 140px;
  height: 30px;
  background-color: blue;
}

.PLEASE-WAIT {
  margin-top: 2px;
  height: 14px;
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  color: #ffffff;
}

.App {
  text-align: center;
  color: #000;
  line-height: 1.5;
}

.CloseButton {
  width: 22px;
  height: 22px;
  object-fit: contain;
  position: absolute;
  margin-top: 20px;
  left: 20px;
  cursor: pointer;
}

.divclass {
  position: absolute;
  width: 98%;
  display: flex;
  justify-content: flex-end;
}

.video-info {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #9b9b9b;
  margin-top: 0px;
  color: #9b9b9b;
  margin-top: 0px;
  text-align: justify;
}

.Loading {
  width: 50px;
  height: 15px;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.triangle-4 {
  box-sizing: inherit;
  width: 9px;
  margin-bottom: 3px;
  height: 5px;
  border-bottom: solid 5px;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
}

.triangle-5 {
  box-sizing: inherit;
  width: 9px;
  margin-top: 3px;
  height: 5px;
  border-top: solid 5px;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
}

.triangle {
  box-sizing: inherit;
  display: inline-grid;
  vertical-align: middle;
}

.video-info-collection {
  margin-top: 0px;
  color: #8e8e93;
  margin-top: 0px;
  text-align: center;
}

.CollectionPageCardButton {
  z-index: 10000000;
  width: 24px;
  margin-top: 10px;
  height: 24px;
  margin-right: 10px;
  /* margin-left: 280px; */
  cursor: pointer;
  /* position: absolute; */
}
.left-border {
  border-left: 8px solid var(--main-primary-color);
  padding-left: 16px;
  margin-left: -22px;
}
.CardfavButton {
  z-index: 10000000;
  width: 24px;
  margin-right: 10px;
  margin-top: 10px;
  height: 24px;
  cursor: pointer;
}

.favButton {
  width: 24px;
  height: 24px;
}

.headerLinks {
  min-height: 30px;
  margin-left: 25px;
  width: 100px;
  font-size: 10px;
  color: #ffffff;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 0.3;
}

.headerLinks-fitness {
  min-height: 30px;
  margin-left: 23px;
  width: 100px;
  cursor: pointer;
  height: 28px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
}

.wexer-secondarycolor-headerLinks {
  min-height: 30px;
  margin-left: 25px;
  width: 100px;
  font-size: 10px;
  color: #d41c00;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 0.3;
}

.puregym-secondarycolor-headerLinks {
  min-height: 30px;
  margin-left: 25px;
  width: 100px;
  font-size: 10px;
  color: #d41c00;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 0.3;
}

.CloseDialogButton {
  position: absolute;
  top: 20px;
  left: 10px;
  margin: 25px;
  cursor: pointer;
}

.dynamiclinks {
  text-decoration: none;
  color: var(--main-primary-color) !important;
}

.dynamiclinks:visited {
  text-decoration: none;
  color: var(--main-primary-color) !important;
}

.bhlive.dynamiclinks {
  color: #8e8e93 !important;
}

.bhlive.dynamiclinks:visited {
  text-decoration: none;
  color: #8e8e93 !important;
}

.EditImgMobile {
  margin-left: 15px;
  height: 20px;
  width: 20px;
  display: flex;
}

.logoutButtonHolder,
.floatLeft {
  text-align: left;
}

.SubIntro {
  font-size: 20px;
}

.SubIntro a {
  text-decoration: none;
}

.noneDisplay {
  display: none;
}

.AccessCodeTextFeild {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
}

.playerButtonShape {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-right: 20px;
  cursor: pointer;
}

.PlayerButtonHolder {
  height: 100%;
  display: flex;
  align-items: center;
  float: right;
  right: 0;
  position: absolute;
}

.loginButton {
  margin: 0 auto;
}

.logoHolder {
  cursor: pointer;
  vertical-align: middle;
  width: 38%;
  flex-basis: 42%;
  &.flex-auto {
    flex-basis: auto;
    max-width: 600px;
    // width: auto;
  }
}

.logoImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 0px;
}

.login-panel {
  width: 50%;
  height: 48px;
  /* max-width: 285px; */
}

.bannerTextBottom {
  text-align: left;
  opacity: 0.7;
  color: white;
}

.TitleBreaker {
  width: 75px;
  max-height: 14px;
  overflow: hidden;
  /* background-color: #d8232a; */
  margin: 0 auto;
  position: relative;
  padding-bottom: 5%;
}

.TitleBreaker div {
  height: 14px;
}

.mainHolder {
  /* overflow: hidden; */
  min-height: 76vh;
  padding-bottom: 0;
  background-color: transparent !important;
  &.mainHolder-oauth {
    min-height: calc(100vh - 485px);
    @media only screen and (min-height: 667px) and (max-height: 823px) and (orientation: portrait) {
      min-height: calc(100vh - 436px);
    }
    @media only screen and (min-height: 823px) and (max-height: 1025px) and (orientation: portrait) {
      min-height: calc(100vh - 474px);
    }
  }
}

.page-container {
  position: relative;
  /* min-height: 100vh; */
}
.MuiSkeleton-pulse.Trailer.thumbnail:hover,
.MuiSkeleton-root.Trailer.thumbnail:focus {
  cursor: default !important ;
}
.livePlayer-info {
  position: absolute;
  top: 0;
  width: 100% !important;
  /* width: -moz-available; */
  /* z-index: 999; */
  height: calc(100% - 0px);
  background: rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  color: #ffffff;
  // padding: 16px;
  text-align: left;
}

// .livePlayer-info2 {
//   position: absolute;
//   width: 61vw;
//   top: 0px;
//   text-align: left;
// padding: 16px;
//   z-index: 10;
// max-width: 970px;
// }

.footerText {
  position: relative;
  background: var(--footer-color);
  padding-top: 60px;
  color: #fff;
  margin-top: 150px;
}
.ewfooterSpace {
  position: relative;
  background: #fff;
  padding-top: 50px;
  color: #fff;
  margin-top: 150px;
}

.loginButtonHolder a .MuiTypography-button,
.UnsupportedDivP {
  font-family: SFProDisplay-Semibold;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: -0.2px;
  text-align: right;
  /* color: #000000; */
}

.down-arrow {
  bottom: -12px;
  cursor: pointer;
  transform: rotate(-90deg);
}
.mobileArrow {
  display: none;
}

.fvrt-star {
  max-width: 25px;
  min-width: 25px !important;
  height: 25px !important;
  padding: 0 !important;
  float: right;
}

.fvrt-star:hover,
.noHover:hover {
  background-color: transparent !important;
  border: 0 !important;
}

.m-auto {
  margin: auto;
}
.m-auto-imp {
  margin: auto !important;
}

.hidden-lg {
  display: none;
}

.displayFlex {
  display: flex;
}

.p-l-0 {
  padding-left: 0 !important;
}

.p-r-0 {
  padding-right: 0 !important;
}
.MuiDrawer-paperAnchorLeft {
  width: 100%;
  max-width: 442px;
}
.customFilter {
  padding: 16px;
  color: rgb(0, 0, 0) !important;
  height: 100vh;
}
.footer-fixed {
  position: fixed;
  bottom: 1em;
  width: 90% !important;
  max-width: 416px;
  background-color: #fff;
  &.m-2 {
    bottom: 3em;
  }
}
.filterOverflow {
  height: calc(100vh - 250px);
  overflow-y: auto;
  padding-right: 10px;
}
/* .filter-footer {
  position: absolutue;
  width: 92%;
  bottom: 22px;
  background: #fff;
} */
.filterOverflow::-webkit-scrollbar {
  display: none;
}
.MuiDrawer-paper {
  bottom: 0;
}
@media (min-width: 1920px) {
  // .page-container {
  //   position: relative;
  //   min-height: 100vh;
  //   max-width: 1816px;
  //   margin: auto;
  // }
  .staticBlock.scroll > div {
    margin: auto;
    width: 1816px;
  }
  .staticBlockEw.scroll > div {
    margin: auto;
    width: 1816px;
  }
  .container {
    width: 100%;
  }
  .overlayMobile {
    display: none;
  }
  .custom-drop,
  .options {
    max-width: 100% !important;
  }
  .Footer {
    // width: 94%;
    clear: both;
    text-align: left;
    min-height: 160px;
    // max-width: 1816px !important;
    margin: auto;
  }
  .cotd2 .Trailer {
    max-width: 92% !important;
  }
}

.ClassPlayer.video-js .vjs-tech {
  object-fit: fill !important;
}

@media (min-width: 1200px) and (max-width: 1279px) {
  // .Trailer,
  // .thumbnail {
  //   height: auto !important;
  // max-height: 328px !important;
  // }
  .cotd .Trailer {
    // height: 675px !important;
    max-height: fit-content;
  }
  .cotd2 .Trailer {
    height: 144px !important;
  }
}

@media (min-width: 1280px) and (max-width: 4000px) {
  /* .App-intro-wexer {
        color: #fff !important;
    } */
  // .Trailer,
  // .thumbnail {
  //   height: auto !important;
  //   max-height: 214px !important;
  // }
  .cotd .Trailer {
    // max-height: 337.5px !important;
    //height: auto !important;
    //max-height: fit-content;
  }
  .cotd2 .Trailer {
    height: 144px !important;
  }
  .staticBlockEw.scroll {
    position: fixed;
    top: 8vh;
    padding-top: 15px;
    padding-bottom: 15px;
    background: #fff;
    z-index: 999;
    width: 100%;
    left: 0;
  }
}

@media (min-width: 1200px) and (max-width: 4000px) {
  // .page-container {
  //   position: relative;
  //   min-height: 100vh;
  //   max-width: 1200px;
  //   margin: auto;
  // }
  .staticBlock.scroll > div {
    width: 1200px;
    margin: 0 80px;
  }
  .staticBlockEw.scroll > div {
    width: 1200px;
    margin: auto;
  }
  .deskSpace-30 {
    margin-top: 30px !important;
  }

  .overlayMobile {
    display: none;
  }
  .p-gt-sm-5 {
    padding-left: 5%;
    padding-right: 5%;
  }
  .overlayMobile {
    display: none;
  }
  // .livePlayer.video-wrapper {
  //   min-width: 63.7vw !important;
  // }
  /* .channelHome .Trailer{
    width: 378px !important;
  }
  .channelHome .channelHomeContainer{
    width: 378px !important;
  }
  .CollectionHome .Trailer{
    width: 378px !important;
  }
  .CollectionHome .workout-footer{
    width: 378px !important;
  } */
  .staticBlockEw.scroll {
    position: fixed;
    top: 8vh;
    padding-top: 15px;
    padding-bottom: 15px;
    background: #fff;
    z-index: 999;
    width: 100%;
    left: 0;
  }
}

@media (min-width: 720px) and (max-width: 1200px) {
  // .page-container {
  //   position: relative;
  //   min-height: 100vh;
  //   width: 92%;
  //   padding-right: 4%;
  //   padding-left: 4%;
  // }
  .staticBlock.scroll > div {
    width: 92%;
    margin: auto;
  }
  .staticBlockEw.scroll > div {
    width: 92%;
    margin: auto;
  }
  .ipadSpace-15 {
    margin-top: 15px !important;
  }

  .overlayMobile {
    display: none;
  }
  .centeredText {
    top: 50% !important;
  }
  .browse-label {
    right: 105px !important;
  }
  .staticBlockEw.scroll {
    position: fixed;
    top: 6vh;
    padding-top: 15px;
    padding-bottom: 15px;
    background: #fff;
    z-index: 999;
    width: 100%;
    left: 0;
  }
}

@media (min-width: 720px) and (max-width: 1279px) {
  .cardList > .MuiGrid-item:last-child,
  .cotdShimmerCards .MuiSkeleton-root,
  .hidden-md {
    display: none;
  }
  .cardList.no-hidden > .MuiGrid-item:last-child,
  .cardList > .MuiGrid-item:first-child,
  .cardList > .MuiGrid-item:nth-child(2),
  .cotdShimmerCards .MuiSkeleton-root:first-child {
    display: block;
  }
  .cotdShimmerCards .MuiSkeleton-root:first-child {
    width: 100% !important;
  }
  .live-label {
    font-size: 20px;
  }
  .livePlayer-info .timer {
    font-size: 40px;
    line-height: 1.3;
    letter-spacing: -0.8px;
  }
  .v-center {
    align-self: flex-start;
    padding-bottom: 10px;
  }
  .p-gt-sm-5 {
    padding-left: 5%;
    padding-right: 5%;
  }
  .cotd2 {
    text-align: left !important;
  }
  .cotd2 .workout-footer {
    margin-left: 0 !important;
  }
  .left-border {
    border-left-color: transparent;
  }
  .ipadScrollParent {
    width: 100% !important;
  }
  .ipadScroll {
    overflow-x: auto;
    overflow-y: hidden;
  }
  .ipadScroll::-webkit-scrollbar,
  .ipadScroll::-webkit-scrollbar {
    background: #fff;
  }
  .ipadScroll .flexNoWrap {
    flex-wrap: nowrap !important;
  }
  .browseCategory .makeTextClickable {
    white-space: nowrap;
  }
  .staticBlockEw.scroll {
    position: fixed;
    top: 6vh;
    padding-top: 15px;
    padding-bottom: 15px;
    background: #fff;
    z-index: 999;
    width: 100%;
    left: 0;
  }
}

@media (min-width: 600px) and (max-width: 848px) {
  /* .loginButtonHolder a .MuiTypography-button {
        font-size: 1.59rem !important;
    } */
  // .MuiTabs-scrollButtonsDesktop {
  //   display: none !important;
  // }

  .headerLinks-fitness,
  .secondarycolor-headerLinks-fitness {
    margin-left: 8px;
  }
  .squareImageContainer .imageContainer,
  .squareImageContainer .imageContainer img {
    max-width: 164px !important;
    max-height: 164px !important;
  }
  .squareImageContainer.MuiGrid-spacing-xs-4 {
    width: calc(100% + 16px);
    margin: -8px;
  }
  .ipadScrollParent {
    width: 100% !important;
  }
  .ipadScroll {
    overflow-x: auto;
    overflow-y: hidden;
  }
  .ipadScroll::-webkit-scrollbar,
  .ipadScroll::-webkit-scrollbar {
    background: #fff;
  }
  .ipadScroll .flexNoWrap {
    flex-wrap: nowrap !important;
  }
  .browseCategory .makeTextClickable {
    white-space: nowrap;
  }
  .loginButtonHolder {
    .MuiGrid-container {
      flex-wrap: nowrap;
    }
  }
}

@media (max-width: 719px) {
  // .page-container {
  //   position: relative;
  //   min-height: 100vh;
  //   max-width: 600px;
  //   margin: auto;
  //   width: 92%;
  // }
  .filterOverflow {
    height: calc(100vh - 222px);
  }

  .liveShimmerCards .MuiSkeleton-root {
    height: 25vh !important;
  }
  .cotdShimmerCards .MuiSkeleton-root:first-child {
    width: 100% !important;
  }

  .staticBlock.scroll > div {
    width: 93%;
    margin: auto;
    max-width: 600px;
  }
  .staticBlockEw.scroll > div {
    width: 93%;
    margin: auto;
    max-width: 600px;
  }
  .Clear-filters {
    margin-bottom: 16px;
  }
  .PageTitleGrid {
    padding-bottom: 12px !important;
  }
  //.container {
  //  color: #000 !important;
  //}
  .centeredTextDescription {
    width: auto !important;
  }
  // .overlayWeb {
  //   display: none;
  // }
  .fvrt-star {
    /* position: absolute !important;
    right: 0; */
    margin-top: -5px !important;
  }
  .loginButtonHolderMobile {
    display: block !important;
    .MuiGrid-container {
      flex-wrap: nowrap;
    }
  }
  .loginButtonHolder {
    display: none;
  }
  .down-arrow {
    bottom: 0px;
    right: 16px;
    float: right;
  }
  .mobileArrow {
    display: block !important;
  }
  .SearchFeildHolderTextFeild {
    width: calc(100% - 116px) !important;
    max-height: 48px;
  }
  .find-search {
    max-width: 100px;
    max-height: 48px;
  }
  .SearchFeildHolderButton {
    float: right;
  }
  .channel-desc {
    height: auto;
  }
  .squareImageContainer .bottomText p {
    font-family: SFProText;
  }
  .squareImageContainer .bottomText {
    padding: 6px 6px;
  }
}

.text-right {
  text-align: right !important;
}

.subscribe-dialog .MuiDialogTitle-root,
.subscribe-dialog .MuiDialogContent-root {
  padding-left: 65px;
  padding-right: 64px;
}

.subscribe-dialog h1 {
  line-height: 1 !important;
  letter-spacing: -0.39px !important;
}

.subscribe-dialog h4 {
  line-height: 1.33 !important;
  /* letter-spacing: -0.39px !important; */
}

.subscribe-dialog .MuiTypography-overline {
  line-height: 1.23 !important;
}

@media (max-width: 600px) {
  .channelbg-banner img.channel-profileImg {
    width: 40px;
    height: 40px;
    /* margin-top: 24px; */
  }
  .channelbg-banner .channelDescription {
    color: #8e8e93;
    margin-top: 22px;
  }
  .MuiDialog-paperWidthSm {
    max-width: 344px !important;
  }
  .subscribe-dialog h1 {
    font-size: 28px !important;
  }
  .subscribe-dialog .MuiDialogTitle-root,
  .subscribe-dialog .MuiDialogContent-root {
    padding-left: 16px;
    padding-right: 16px;
  }
  .loginButtonHolderMobile {
    display: block !important;
    .MuiGrid-container {
      flex-wrap: nowrap;
    }
  }
  .loginButtonHolder {
    display: none;
  }
  /* .logoHolder {
    max-width: 230px;
  } */
  .textLeft-xs {
    text-align: left !important;
  }
  .squareImageContainer .imageContainer,
  .squareImageContainer .imageContainer img {
    max-width: 100px !important;
    max-height: 100px !important;
  }
  .squareImageContainer.MuiGrid-spacing-xs-4 {
    width: calc(100% + 8px) !important;
    margin: -4px !important;
  }
  .squareImageContainer.MuiGrid-spacing-xs-4 > .MuiGrid-item {
    padding: 4px;
  }

  .browseCategory .makeTextClickable {
    margin-right: 24px !important;
    white-space: nowrap;
    font-family: "SFProDisplay-Semibold" !important;
    font-size: 17px !important;
    line-height: 1.41;
  }

  .cardList > .MuiGrid-item:last-child,
  .cardList > .MuiGrid-item {
    display: block !important;
    min-width: 328px;
    min-height: 175px;
  }
  .Trailer {
    max-height: 272px !important;
  }

  .channelHome .Trailer {
    max-height: 176px !important;
  }
  .CollectionHome .Trailer {
    max-height: 176px !important;
  }
}

.livePlayer .workOutCompleted {
  text-align: center;
  color: white;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  height: 36vw;
  width: 64vw;
  z-index: 1000;
  left: unset;
  border-radius: 16px;
  top: 0;
}

.Footer {
  // width: 94%;
  clear: both;
  text-align: left;
  min-height: 160px;
  // max-width: 1200px;
  margin: auto;
}

.Footer h3 {
  margin-bottom: 10px;
}

.Footer a {
  /* font-size: 14px; */
  text-align: center;
  text-decoration: none;
  color: inherit;
  margin: 0;
}

.Footer p.copyStyle {
  color: #9b9b9b;
  font-size: 13px;
}

.Footer p {
  text-align: left;
  text-decoration: none;
  color: #000000;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.85;
  letter-spacing: -0.3px;
  margin: 0px;
}

.policyLinkHolder {
  background-color: #3c3c3b;
  text-align: left;
  padding-top: 10px;
}

.TncHolder {
  margin: 0;
}

.LinkHolder {
  padding-top: 10px;
  text-align: left;
  padding-left: 6vw;
  width: 41%;
}

.App-intro-wexer {
  overflow: hidden;
  // padding-bottom: 10px;
}

.flex {
  flex-grow: 1;
}

.image {
  object-fit: cover;
  object-position: center;
  width: 100%;
  position: relative;
  display: block;
  border-radius: 0px;
  height: 76.9vw;
  @media (min-width: 768px) {
    height: 52vw;
  }
  @media (min-width: 1360px) {
    height: 53.24vw;
  }
}

.container {
  position: relative;
  text-align: center;
  color: #fff;
}

.overlay {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  opacity: 0;
  text-align: center;
  opacity: 1;
  border-radius: 8px;
}

.centeredText {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 80%;
  padding: 0 10%;
}
.bottomText {
  height: inherit;
  display: -ms-flexbox;
  display: flex;
  // align-items: end;
  justify-content: flex-end;
  flex-direction: column;
  text-align: left;
  padding: 0px 16px;
  /* background: rgb(0 0 0 / 0.6); */
  border-radius: 8px;
}

.centeredTextDescription {
  width: 650px;
  // min-height: 40px;
  text-align: center;
  color: #fff;
  margin: 0 auto !important;
  // padding-bottom: 20px;
  font-family: SFProDisplay-regular !important;
}

.subTitleText {
  height: 20px;
  /*
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal; */
  text-align: center;
  color: #000000;
}

h1 {
  font-size: 48px;
  font-weight: 800;
  /* line-height: 1; */
}

.headingStyle {
  font-size: 3.3vw;
  font-weight: 800;
}

p {
  font-size: 14px;
  font-weight: 200;
  /* line-height: 1.43; */
}

/* .imgTitle {

    font-size: 40px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    margin-bottom: 0px;
} */
.mainHolder {
  &.mainHolder-sso-support {
    span {
      white-space: pre-line;
    }
  }
}
.imgSubTitle {
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: -0.3px;
  text-align: center;
  color: #8e8e93;
}

.SubscriptionHolder {
  display: block;
}

.alignleft {
  float: left;
  padding-bottom: 1%;
}

.alignright {
  float: right;
  margin-right: 3px;
}

.imgText {
  font-size: 15px;
  font-weight: bold;
  /* line-height: 1; */
  letter-spacing: normal;
  /* color: #3c3c3b; */
  text-align: left;
}

.imgSubText {
  font-size: 13px;
  font-weight: 500;
  color: #727271;
  text-align: left;
}

.trainAtHome {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.trainAtHomeImage {
  background-color: #000;
  width: 30%;
  text-align: left;
  float: left;
  min-height: 150px;
  margin: 15px;
}

.planContainer {
  position: relative;
  text-align: center;
  /* color: #3c3c3b; */
  width: 100%;
}

.clubsContainer {
  position: relative;
  text-align: center;
  /* color: #3c3c3b; */
  padding: 5px;
}

.clubsContainer .description {
  width: 75%;
  margin: 0 auto;
  padding-top: 5px;
}

.clear {
  clear: both;
}

.clearWhite {
  clear: both;
  background-color: #fff;
}

.whiteBack {
  background-color: #fff;
}

.subscriptionContainer {
  width: 70%;
  margin: 0 auto;
  /* background-color: aqua; */
  /* color: #3c3c3b; */
  text-align: center;
}

h3 {
  font-size: 36px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  /* line-height: normal; */
  letter-spacing: normal;
}

.Error {
  font-weight: bold;
  font-size: 12px;
  color: #d0021b;
}

.FreeAccessHomeContainer {
  padding-top: 4vw;
  padding-bottom: 4vw;
}

.hover:hover {
  background-color: var(--hover-color) !important;
}
.hover:hover {
  background-color: var(--hover-color) !important;
}
.subscriptionDuration {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #767675;
  margin-bottom: 10px;
}

.seperator {
  width: 155px;
  height: 5px;
  overflow: hidden;
  margin: 0 auto;
}

.collection-list .clear {
  margin-top: 20px;
}
.collection-list .clear > .MuiGrid-container {
  margin-bottom: 10px;
}
/* ......................... */

.EContainer {
  width: 100%;
  margin: -200px auto 0px auto;
  /* padding-left: 20px;
  padding-right: 20px; */
  overflow: hidden;
}

.EcontainerHolder {
  width: 96%;
  padding-left: 2%;
  padding-right: 2%;
  margin: 0px;
  overflow: hidden;
}

.imageContainer {
  max-width: 100%;
  height: auto;
  position: relative;
  .overlay {
    border-radius: 0px;
  }
  margin-top: 12px !important;
  @media (min-width: 768px) {
    margin-top: 28px !important;
  }
  /* display: block; */
}

.bottomWidth {
  margin-bottom: 8px;
  border-radius: 0px;
}

.noBorderRadiusButton {
  border-radius: 0px !important;
}

.trainAtHomeDimensions {
  height: 30em;
  background-color: #fff;
}

.trainAtHomeImageDimensions {
  margin-left: 6.5em;
  height: 50%;
  width: 65%;
}

.marginClass {
  margin-top: 1em;
}

.marginTop_4 {
  margin-top: 4em;
}

.marginRight_3 {
  margin-right: 3em;
}

.marginClassWidth {
  width: 100% !important;
  margin: 1em auto;
}

.hideBanner {
  visibility: hidden;
}

.buttonMarginTop {
  margin-top: 0.5em !important;
}

.subText {
  color: #8e8e93;
  white-space: pre-line;
}

/* .checkoutForm {
    text-align: left;
} */

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 100%;
  padding: 10px 14px 10px 24px;
  font-size: 13px;
  /* box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px; */
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  outline: 0;
  /* border-radius: 4px; */
  background: white;
}

.StripeElement:hover {
  -webkit-transition: linear 150ms ease;
  transition: linear 150ms ease;
  border-bottom: #000 solid 2px;
}

.StripeElement--focus {
  /* box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px; */
  -webkit-transition: linear 150ms ease;
  transition: linear 150ms ease;
  border-bottom: #000 solid 2px;
}

.StripeElement--invalid {
  border-bottom: #d8232a solid 2px;
}

.freeTrialActiveBanner {
  background-color: #aaaaaa;
  height: 25px;
  overflow: hidden;
}

.freeTrialActiveBanner p {
  padding: 0px;
  margin: 0px;
  font-size: 15px;
  font-weight: normal;
}

.clipLoaderDiv {
  margin: 5px auto;
  width: 100%;
  text-align: center;
}

.subscriptionTagline {
  margin-top: 8px !important;
  margin-bottom: 20px !important;
}

.hrow {
  height: 1px;
  margin-top: 0;
  border: none;
  flex-shrink: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.12);
}

.mtop {
  margin-top: 20px !important;
}

.linkText {
  font-size: 20px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--main-primary-color);
}

.textSecondarySmall {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.padding30 {
  padding: 0px;
  padding-bottom: 7px;
}

.gridLeftAlign {
  text-align: "left" !important;
  line-height: 2;
}

.font14 {
  font-size: 14px !important;
}

.modelWrapper {
  text-align: center;
  top: 30%;
}

.modelCss {
  background-color: white;
  text-align: center;
  margin: 0 auto;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.centerContent.viewForm {
  max-width: 60%;
  margin: auto;
  /* flex-basis: 69%; */
}

.loginButtonHolderMobile,
.customTab .MuiTabs-indicator {
  display: none;
}

/* .loginButtonHolder>div {
    margin-top: 10px;
} */

.modalClose {
  top: 15.5px;
  right: 15.5px;
  box-sizing: content-box !important;
  display: block !important;
  position: absolute !important;
  left: initial;
}

.browse-collection-menu {
  width: 50%;
  max-width: 500px;
  margin: 10px auto;
}

.subscribe-menu {
  margin: 28px auto;
  max-width: 800px;
}

.browse-collection-menu ul li {
  list-style: none;
  padding: 18px 16px;
  font-family: SFProDisplay-Bold;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #000000;
  text-align: left;
  box-shadow: inset 0 -1px 0 0 #eae8e8;
  cursor: pointer;
}

.browse-collection-menu ul li::after {
  float: right;
  width: 43px;
  height: 33px;
  content: " ";
  /* background-image: url(../images/fw.png); */
}

.playerButtons {
  //width: 100% !important;
  max-width: 500px !important;
}

.payButton {
  max-width: 375px !important;
  max-height: 48px !important;
}

.btn-container {
  display: none;
}

.p-tb-16 {
  padding-top: 16px;
  padding-bottom: 20px;
}

.subscribe-input-height {
  min-height: 25px;
  padding-top: 25px;
}

.classes-spinner {
  position: fixed;
  left: 0px;
  top: 45%;
  width: 100%;
  z-index: 9999;
}

.search-filter .classes-spinner {
  top: 70%;
}

.button-link {
  /* color:var(--main-primary-color) !important; */
  width: auto !important;
  padding: 0 !important;
  min-width: 48px !important;
  height: auto !important;
}

.couponCode {
  font-size: 17px !important;
  font-weight: normal !important;
  font-family: SFProDisplay-regular !important;
  color: #8e8e93 !important;
  height: 45px;
}

.couponCode input {
  width: 90%;
  color: #8e8e93 !important;
}

.codeLink {
  display: inline;
  margin-left: -62px !important;
  position: absolute;
  cursor: pointer;
  color: var(--main-primary-color) !important;
  margin-top: 12px !important;
}

.codeLinkDisable {
  display: inline;
  margin-left: -62px !important;
  position: absolute;
  cursor: pointer;
  color: #8e8e93;
  margin-top: 12px !important;
}
.customTab .MuiTab-root {
  min-width: auto;
}
.customTab .Mui-selected {
  border-bottom: none;
  color: var(--main-primary-color) !important;
}
.MuiBox-root {
  padding: 0 !important;
}
.p-lr-24 {
  padding-left: 24px;
  padding-right: 24px;
}

.dialogBtn {
  max-width: 200px !important;
  max-height: 48px !important;
  margin-right: 8px !important;
}
.overlay-text {
  position: absolute;
  width: fit-content;
}
.browseCategory .makeTextClickable {
  min-width: auto !important;
  padding: 0 !important;
  margin-right: 24px !important;
  opacity: 1;
  font-size: 22px;
}

// .browseCategory > .MuiTabScrollButton-root:first-child {
//   position: absolute;
//   min-height: 48px;
//   background-color: #fff;
//   z-index: 999;
//   opacity: 1;
// }
.browseCategory > .MuiTabScrollButton-root.Mui-disabled {
  opacity: 0 !important;
}
.browseCategory .MuiSvgIcon-fontSizeSmall {
  font-size: 26px;
  color: var(--icon-color);
}
.browseCategory::after {
  width: 32px;
  height: 32px;
  //background: url("../../assets/images/fw-green.png");
}
.browseCategory .makeTextClickable:first-child {
  padding-left: 0 !important;
}
/* .browseCategory .MuiTabs-scroller{
  margin-left:-40px !important;
} */
.chipUl .MuiChip-root {
  height: 28px;
  margin-right: 8px;
  background-color: #efeff4;
}
.chipUl .MuiChip-clickable:hover,
.chipUl .MuiChip-clickable:focus {
  background-color: #efeff4;
}
@media only screen and (min-width: 719px) {
  .chipUl .MuiChip-root {
    min-width: 96px;
  }
  .m-l-16 {
    margin-left: 16px !important;
  }
  .p-t-62 {
    padding-top: 62px;
  }
  .m-b-5 {
    margin-bottom: 5px !important;
  }
  .m-t-5 {
    margin-top: 5px !important;
  }
  .m-t-8 {
    margin-top: 8px !important;
  }
  .p-t-30 {
    padding-top: 30px;
  }
  .m-bt-lg-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .bgContainer2.codBg::before {
    height: 200px;
  }
  .m-bt-lg-60 {
    margin-bottom: 26px;
    margin-top: 60px;
  }
  .p-t-32 {
    padding-top: 32px;
  }
  .homeStripe::before {
    top: 86.5%;
  }
  .m-t-15 {
    margin-top: 16px !important;
  }
  .m-t-55 {
    margin-top: 50px !important;
  }
  .m-t-77 {
    margin-top: 77px !important;
  }
  .m-t-40 {
    margin-top: 42px !important;
  }
  .m-b-10 {
    margin-bottom: 10px !important;
  }
  .m-tb-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .m-b-20 {
    margin-bottom: 20px !important;
  }
  .m-b-32 {
    margin-bottom: 32px !important;
  }
  .m-b-120 {
    margin-bottom: 120px !important;
  }
  .m-r-5 {
    margin-right: 5px;
  }
  .m-r-10 {
    margin-right: 10px !important;
  }

  .find-search {
    max-width: 130px;
    margin-top: -8px !important;
    margin-left: 16px !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .mainHolder {
    min-height: 80vh;
  }
  .LinkHolder {
    width: 39%;
  }
}
.m-bt-lg-30 {
  margin-top: 24px;
  margin-bottom: 30px;
  @media (min-width: 768px) {
    margin-top: 32px;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .description h6 {
    font-size: 17px !important;
  }
  .squareImageContainer .imageContainer,
  .squareImageContainer .imageContainer img {
    max-width: 164px !important;
    max-height: 164px !important;
  }
  .squareImageContainer.MuiGrid-spacing-xs-4 {
    width: calc(100% + 16px);
    margin: -8px;
  }
  .squareImageContainer.MuiGrid-spacing-xs-4 > .MuiGrid-item {
    padding: 8px;
  }
  .livePlayer .workOutCompleted {
    width: 704px;
    height: 396px;
    //min-height: 396px;
  }
  // .livePlayer-info2 {
  //   width: -webkit-fill-available;
  //   max-width: 674px;
  // }
  .channel-desc,
  .left-border .MuiTypography-h6 {
    font-size: 17px !important;
  }
  // .MuiTabs-scrollButtonsDesktop {
  //   display: none !important;
  // }
  .staticBlockEw.scroll {
    position: fixed;
    top: 6vh;
    padding-top: 15px;
    padding-bottom: 15px;
    background: #fff;
    z-index: 999;
    width: 100%;
    left: 0;
  }
}
.squareImageContainer .MuiGrid-item:last-child .imageContainer {
  margin-right: 0px !important;
}
@media (max-width: 767px) and (min-width: 450px) {
  /* .livePlayer .workOutCompleted,
  .livePlayer-info {
    width: 96vw;
    max-width: 1040px !important;
    max-height: 584px !important;
    height: 54vw; */
  .livePlayer .workOutCompleted,
  .livePlayer-info2 {
    width: -webkit-fill-available;
    width: -moz-available;
    // height: -webkit-fill-available;
    // height: -moz-available;
  }
  .livePlayer .video-js {
    max-width: 100% !important;
  }
  .channel-desc,
  .topProfileLink .MuiTypography-button {
    font-size: 17px !important;
  }
  .squareImageContainer.MuiGrid-spacing-xs-4 > .MuiGrid-item {
    padding: 4px;
  }
}
.squareImageContainer .imageContainer,
.squareImageContainer .imageContainer img {
  max-width: 173px;
  max-height: 173px;
}
.squareImageContainer .overlay:hover,
.squareImageContainer .overlay:focus {
  background: rgba(0, 0, 0, 0.15);
}
.discIcon .cardDescription-p,
.discIcon .featured-iconText-Player {
  display: inline-block;
  white-space: nowrap;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .mainHolder {
    min-height: 85vh;
  }
  .LinkHolder {
    width: 35%;
  }
  .centerContent.viewForm {
    max-width: 78%;
    /* flex-basis: 69%; */
  }
  .m-bt-md-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  /* .logoHolder {
    max-width: 230px;
    flex-basis: 76%;
  } */
  .cotd .workoutPlayer .Trailer {
    height: 530px !important;
  }
  .Trailer,
  .thumbnail,
  .Trailer:focus {
    width: 100% !important;
    // height: 260px !important;
  }
  /* .loginButtonHolder>div {
        margin-top: 8px;
    } */
}

@media (min-width: 1300px) {
  .mainHolder {
    min-height: 79vh;
    /* padding-top: 14px !important; */
  }
  .LinkHolder {
    width: 42%;
  }
}

@media (max-width: 719px) {
  .mobileScrollParent {
    width: 100% !important;
  }
  .mobileScroll {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .mobileScroll::-webkit-scrollbar,
  .mobileScroll::-webkit-scrollbar {
    background: #fff;
  }
  .browseCategory .MuiTab-root {
    min-width: 30px !important;
  }
  .squareImageContainer,
  .mobileScroll .flexNoWrap,
  .mobileScroll .MuiPaper-rounded {
    flex-wrap: nowrap !important;
  }
  .hidden-xs,
  .cardList > .MuiGrid-item:last-child,
  .cardList > .MuiGrid-item:nth-last-child(2) {
    display: none;
  }
  .hidden-lg,
  .cardList.no-hidden > .MuiGrid-item:last-child,
  .cardList > .MuiGrid-item:first-child {
    display: block;
  }
  .filter-btn.MuiButton-root {
    min-width: 48px !important;
    width: 84% !important;
  }
  .filter-btn img {
    padding-left: 0;
  }
  .left-border {
    border-left-color: transparent;
  }
  /* .logoHolder {
    max-width: 230px;
  } */
  /* .loginButtonHolder>div {
        margin-top: 8px;
    } */
  .m-tb-sm-25 {
    margin-bottom: 25px;
    margin-top: 25px !important;
  }
  .m-r-xs-10 {
    margin-right: 10px !important;
  }
  .m-t-xs-5 {
    margin-top: 5px !important;
  }
  .m-t-xs-minus12 {
    margin-top: -12px !important;
  }
  .m-t-xs-15 {
    margin-top: 15px !important;
  }
  // .m-t-xs-20 {
  //   margin-top: 20px !important;
  // }
  .m-t-xs-32 {
    margin-top: 32px !important;
  }
  .m-b-xs-20 {
    margin-bottom: 20px !important;
  }
  .m-b-xs-10 {
    margin-bottom: 10px !important;
  }
  .m-b-xs-5 {
    margin-bottom: 5px !important;
  }
  .m-t-xs-16 {
    margin-top: 16px !important;
  }
  .p-t-xs-16 {
    padding-top: 16px;
  }
  .bgContainer.homeStripe::before {
    top: 70.5%;
  }
  .customClosePlayer {
    margin-right: 0px;
  }
  .p-lr-xs-15 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .p-l-xs-15 {
    padding-left: 15px;
  }
  .browse-collection-menu {
    width: 100%;
  }
  .browse-collection-menu ul {
    padding-left: 0;
  }
  .browse-collection-menu ul li {
    padding-right: 0;
  }
  .btn-container {
    display: block;
    position: absolute;
    right: 6px;
    margin-top: 3px;
  }
  .p-r-s-72 {
    padding-right: 72px;
  }
  .CardField-number {
    max-width: 120px;
  }
}

@media (max-width: 700px) {
  .App-intro-wexer {
    /* text-transform: uppercase; */
    // padding-bottom: 7px;
  }
  .CollectionPageCardButton {
    z-index: 10000000;
    width: 24px;
    margin-top: 10px;
    height: 24px;
    margin-right: 10px;
    /* margin-left: 200px; */
    cursor: pointer;
    /* position: absolute; */
  }
  .seperator {
    width: 70px;
  }
  .subscriptionDuration {
    font-size: 12px;
  }
  .mobileDisplayNone {
    display: none;
  }
  .centeredText {
    width: 90%;
    padding: 0 5%;
  }
  .playerButtonShape {
    height: 17px !important;
    width: 17px !important;
    margin-right: 15px;
  }
  form.editForm .terms span:nth-child(2) {
    /* margin-top: 15px; */
    font-size: 12px;
  }
  .login-panel {
    /* position: absolute;
    right: 15px; */
    width: auto;
  }
  .logoutButtonHolder {
    text-align: center;
  }
}

@media (max-width: 450px) {
  .channel-desc,
  .topProfileLink .MuiTypography-button {
    font-family: SFProDisplay-regular !important;
    font-size: 17px !important;
    line-height: 1.41 !important;
    letter-spacing: -0.3px !important;
    height: auto;
    max-height: 48px;
  }
  .channelHome {
    .channel-desc {
      min-height: 48px;
    }
  }
  .livePlayer {
    min-height: 211px;
  }
  .livePlayer.video-wrapper .video-js {
    width: 100vw;
    height: 56vw;
  }
  .livePlayer .workOutCompleted {
    width: 90vw;
    min-height: -webkit-fill-available;
    // padding: 0 32px;
  }
  .livePlayer.video-wrapper {
    width: 107% !important;
    margin-left: -13px;
    overflow: visible;
  }
  .livePlayer.video-wrapper img {
    width: 107%;
  }
  .livePlayer-info {
    //padding: 5px 15px !important;
    width: 100% !important;
  }
  .livePlayer-info2 {
    width: 100vw !important;
  }
  .livePlayer-info2 .btn-container {
    margin-top: -3px;
  }
  .live-label {
    font-size: 14px;
    vertical-align: middle;
  }
  .livePlayer .timeDayText {
    font-size: 20px;
    font-family: SFProDisplay-Semibold !important;
  }
  .livePlayer-info .timer {
    font-size: 28px;
    line-height: 1.14;
    letter-spacing: -0.2px;
    bottom: 8px;
  }
  .livePlayer-info .btn-container {
    margin-top: 0px;
  }
  .padding16 {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 0px;
  }
  .padding16Left {
    padding-left: 16px;
  }
  .padding16Right {
    padding-right: 16px;
  }
  .dialogBtn {
    max-width: 90% !important;
  }
  .mobileMargin {
    margin-top: 30px;
  }
  .centerContent.viewForm {
    max-width: 90%;
  }
  .CollectionPageCardButton {
    z-index: 10000000;
    width: 24px;
    margin-top: 10px;
    height: 24px;
    margin-right: 10px;
    /* margin-left: 90%; */
    cursor: pointer;
    /* position: absolute; */
  }
  .imgTitle {
    text-align: center;
    float: none;
  }
  /* .EditImgMobile{
        display: block;
      } */
  .logoHolder {
    text-align: left;
    /* width: 170px; */
    width: 50%;
    flex-basis: 78%;
    /* height: 30px; */
    img {
      width: auto;
      max-width: 300px;
      height: 100%;
      max-height: 40px;
      object-fit: contain;
    }
    &.flex-auto {
      flex-basis: auto;
      img.logoImg {
        max-width: 200px;
      }
    }
  }
  .cotd .workoutPlayer {
    width: 100%;
    height: 193px !important;
  }

  .channelHome .workoutPlayer {
    width: 312px !important;
    height: 176px !important;
  }
  .channelHome .workout-footer {
    width: 312px !important;
  }
  .channelHome .channelHomeContainer {
    width: 312px !important;
  }

  .livstreamHome .workoutPlayer {
    width: 312px !important;
    height: 175px !important;
  }

  .CollectionHome .workoutPlayer {
    width: 312px !important;
    max-height: 175px !important;
  }
  .CollectionHome .workout-footer {
    width: 312px !important;
  }

  .livstreamHome .Trailer,
  .thumbnail {
    // max-height: 214.25px !important;
    height: auto !important;
  }

  .collectionPage .workoutPlayer {
    width: 312px !important;
  }
  .collectionPage .workout-footer {
    width: 312px !important;
  }
  .collectionPage .Trailer {
    max-height: 214.25px !important;
    height: auto !important;
  }

  .thumbnail {
    // max-height: 214.25px !important;
    height: auto !important;
  }
  .cotd .workout-footer {
    margin-top: 15px !important;
  }
}
@media (max-width: 767px) {
  .play-list-info-heading-btn {
    .MuiTypography-button {
      font-family: SFProDisplay-Semibold !important;
      font-size: 20px !important;
    }
  }
}
@media (max-width: 380px) {
  .thumbnail {
    // max-height: 194.06px !important;
    height: auto !important;
  }
  .cotd .workout-footer {
    margin-top: -6px !important;
  }
}

/* ---------------------------- */

.mainHolder div.centerContent {
  margin: 10px auto;
}

.termsMargin {
  margin-top: 11px;
  margin-bottom: -8px;
}

form.editForm {
  display: block;
  margin: auto;
}

form.editForm [class^="MuiFormControl"] {
  padding-bottom: 5px;
  /* margin: normal; */
}

.loginInputs label + .MuiInput-formControl {
  margin-top: 19px;
}

.signupInputs .MuiFormControl-root {
  min-height: 59px;
}
.sort-dropdown .MuiFormControl-root {
  min-height: 0px;
}
/* form.editForm [class^="jss145"] {
      padding-bottom: 8px;
      margin: normal;
    } */

.marginFormTop {
  margin-top: 36px;
}

/*
    .marginFormTop >*{
      max-height: 56px;
      overflow: hidden;
    } */

.margin-top-16 {
  margin-top: 16px;
}

form.editForm .terms span:nth-child(2) {
  /* margin-top: 15px; */
  font-size: 12px;
}

.subText.ft-12 {
  font-size: 12px;
}

.subText.ft-12withMargin {
  font-size: 12px;
  margin-bottom: 43px;
}

.subText.ft-12 a span {
  margin-left: 10px;
}

.Footer p.copyStyle {
  color: #9b9b9b;
  font-size: 13px;
}

.SubTitle.alignleft span {
  font-size: 20px;
  font-weight: 900;
  font-style: italic;
}

[class^="MuiGrid-item"]:nth-child(2) {
  font-weight: 900;
}

.mbottom-57 {
  margin-bottom: 57px !important;
}

.mbottom-why57 {
  margin-bottom: 0px !important;
}

.mailSentMsg {
  margin: 0 auto;
  margin-top: 43px;
}

.accountRightLabel {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #727271;
}

.bold {
  font-weight: bold;
  color: #3c3c3b;
}

.CloseNotification {
  position: absolute;
  float: right;
  margin-top: -5px;
  margin-right: 10px;
  right: 0;
  top: 0;
  cursor: pointer;
}

.errorbold > p {
  font-weight: bold;
}

.transformationcontainer {
  width: 95%;
  padding-left: 40px;
  padding-right: 20px;
  text-align: center;
}

.imagecaptions {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.83;
}

.tripleDotsPage {
  box-shadow: inset 0 -1px 0 0 #eae8e8;
  background-color: #ffffff;
  align-items: center;
  justify-content: space-between;
}

/* Common classes created for spacing by Bhawika */

.terms .MuiIconButton-colorSecondary:hover,
.terms .MuiCheckbox-colorSecondary.Mui-checked:hover,
.button-link:hover {
  background-color: transparent !important;
}

.radius-bottom0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.customClose {
  position: static;
  float: right;
  margin-top: 0;
  width: 32px;
  height: 32px;
  @media screen and (min-width: 768px) {
    width: 32px;
    height: 32px;
  }
  @media screen and (min-width: 1360px) {
    width: 40px;
    height: 40px;
  }
  @media screen and (min-width: 2000px) {
    width: 48px;
    height: 48px;
  }
}

.livePlayer {
  position: relative;
}

.livePlayerClose {
  position: static;
  float: right;
  @media screen and (min-width: 768px) {
    width: 30px;
    height: 30px;
    margin-top: 2px;
  }
  @media screen and (min-width: 1360px) {
    width: 32px;
    height: 32px;
    margin-top: 3px;
  }
  @media screen and (min-width: 2000px) {
    width: 40px;
    height: 40px;
    margin-top: 4px;
  }
  width: 36px;
  height: 36px;
  border-radius: 40px;
  margin-top: 0px;
}

.live-label {
  text-transform: uppercase;
  border-radius: 4px;
  background-color: #ff3b30;
  color: #fff;
  padding: 0px 5px 0 4px;
  margin-right: 8px;
}

.timer {
  font-size: 70px;
  font-family: SFProDisplay-Semibold;
  line-height: 0.74;
  position: absolute;
  bottom: 20px;
  margin: 0;
}

.p-r-70 {
  padding-right: 70px;
}

.text-strike {
  text-decoration: line-through;
}

.disabledText,
.text-gray {
  color: #8e8e93 !important;
}

.text-black {
  color: #000000 !important;
}

.text-white {
  color: #ffffff !important;
}

.text-lightgray,
.colorImp {
  color: #a3a3a8;
}

.text-center {
  text-align: center !important;
}

.v-center {
  align-self: center;
}

.align-left {
  text-align: left !important;
}

.valign-end {
  align-self: flex-end;
  text-align: end;
}

.align-bottom {
  vertical-align: bottom;
}
.align-middle {
  vertical-align: middle;
}
.filledStar {
  width: 22px;
  height: 22px;
}

.browse-label {
  position: absolute;
  min-width: 200px;
  right: 60px;
  top: 15px;
}

.fvrt-text {
  margin-top: -5px !important;
  margin-bottom: 15px !important;
  color: #000;
}

.featured-iconText {
  font-size: 22px !important;
  margin-left: 10px !important;
  line-height: 1.9 !important;
  min-width: 120px;
  text-align: left;
}

/* player */

.vjs-play-progress {
  background-color: var(--main-primary-color) !important;
}

.kebab {
  padding: 0px !important;
  .icon-close {
    height: 30px;
    width: 30px;
    color: var(--main-primary-color);
  }
}

.menuItem {
  top: 50px;
  left: 100px;
}

.MuiButton-textSizeSmall,
.MuiButton-sizeSmall {
  width: 300px !important;
}

.MuiButton-textSizeLarge,
.MuiButton-sizeLarge {
  max-width: 500px !important;
}
@media only screen and (min-width: 768px) and (max-width: 1359px) {
  .add-playlist-popup-btn {
    width: 382px !important;
    height: 48px !important;
    border-radius: 24px !important;
    margin: 0px 16px 0px 16px !important;
  }
}
@media only screen and (max-width: 767px) {
  .add-playlist-popup-btn {
    width: calc(100% - 32px) !important;
    height: 48px !important;
    border-radius: 24px !important;
    margin: 0px 16px 0px 16px !important;
  }
}

.textCapitalize {
  text-transform: capitalize;
}

.MuiDialogContent-root {
  margin-bottom: 18px !important;
}

.MuiDialog-paper {
  width: 800px;
  height: 496px;
  border-radius: 10px;
}

.Dialogsize {
  width: 200px;
  height: 300px;
}

.Headingcolor {
  color: #99999e;
  margin: 0 auto;
}

.textfieldstyle {
  width: 500px;
  height: 64px;
  margin-bottom: 40px;
  margin: 0 auto;
}

.textfieldreset {
  max-width: 500px;
  height: 59px;
  width: 100%;
}

.formstyle {
  max-width: 500px;
  margin: 10px auto;
}

.MuiInputBase-input {
  margin-bottom: 8.8px;
}

.marginbottom1 {
  margin-bottom: 17px;
}

.worddown {
  max-width: 686px;
  /* margin: 0 auto; */
  word-wrap: break-word;
}

.centeralign {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.UnsupportedDiv {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.workout-info {
  position: absolute;
  /* top: 0; */
  width: -webkit-fill-available;
  width: -moz-available;
  z-index: 999;
  height: calc(100% - 32px);
  background: rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  color: #ffffff;
  padding: 16px;
  text-align: left;
}

.adjustTitleWidth {
  max-width: calc(100% - 40px);
}

.facCodeEnterButton {
  width: 100% !important;
  max-width: 500px !important;
  height: 64px;
}

.ResendMarketingMail > span {
  color: #fa4c32;
  text-decoration: underline;
  cursor: pointer;
}

.tncTitle {
  text-decoration: none;
  color: var(--main-primary-color) !important;
  min-width: 250px;
}

.mgl {
  margin-left: -11px;
  min-width: 300px;
}

@media (min-width: 250px) and (max-width: 1100px) {
  .tncTitle {
    min-width: 200px;
    font-size: 15px !important;
    margin-top: 5px;
  }
}

.marginab {
  margin-top: -10px;
}

.minwid {
  min-width: 250px;
}

.bg-placeholder {
  width: 100%;
  border-radius: 0;
  overflow-y: hidden;
}

.transition {
  animation: fadeIn 3s;
}

.tnctext {
  display: flex;
  flex-direction: row;
}

.iconColor {
  margin-left: -2px;
  vertical-align: bottom;
  color: var(--icon-color);
}

.cotdDay {
  font-family: SFProRounded-Regular;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #000;
}

.loader-caption {
  position: absolute;
  font-family: SFProDisplay-Regular;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 20px);
}

.livePlayer-loader .classes-spinner {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.searchFilter #input-with-icon-textfield::-webkit-search-cancel-button {
  display: none;
}
.searchFilter .MuiSvgIcon-root {
  border-bottom: none;
  height: 24px;
  width: 24px;
}
.searchFilter .MuiInputBase-input {
  font-family: SFProDisplay-Regular;
  font-size: 16px;
  font-weight: normal;
}

.fllPlayer .vjs-progress-control,
.fllPlayer .vjs-remaining-time {
  display: none !important;
}

.fllPlayer .vjs-live-control {
  display: flex !important;
}

@media only screen and (min-width: 540px) and (max-width: 590px) {
  .cotd .workoutPlayer .Trailer {
    height: auto !important;
    max-height: 300px !important;
  }

  .cotd .Trailer {
    height: auto !important;
    max-height: 300px !important;
  }

  .Trailer,
  .thumbnail,
  .Trailer:focus {
    width: 100% !important;
    // max-height: 210px !important;
    //height: auto !important;
  }
  .fixedWidthContainer {
    min-height: 158px !important;
  }
}
@media only screen and (min-width: 590px) and (max-width: 768px) {
  .cotd .workoutPlayer .Trailer {
    height: auto !important;
  }

  .cotd .Trailer {
    height: auto !important;
    max-height: 387px !important;
  }

  .Trailer,
  .thumbnail,
  .Trailer:focus {
    width: 100% !important;
    //height: auto !important;
    //  max-height: 190px !important;
  }
  .fixedWidthContainer {
    min-height: 165px !important;
  }
}
@media only screen and (min-width: 500px) and (max-width: 600px) {
  .fixedWidthContainer {
    min-height: 130px !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 720px) {
  .cotd .workoutPlayer .Trailer {
    height: 337px !important;
  }

  .cotd .Trailer {
    max-height: 337px !important;
  }

  .Trailer,
  .thumbnail,
  .Trailer:focus {
    width: 100% !important;
    // max-height: 338px !important;
    //height: auto !important;
  }
  .fixedWidthContainer {
    min-height: 185px !important;
  }
}
@media only screen and (min-width: 720px) and (max-width: 768px) {
  .fixedWidthContainer {
    min-height: 200px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 848px) {
  .cotd .workoutPlayer .Trailer {
    max-height: 420px !important;
    height: auto !important;
  }
  .Trailer,
  .thumbnail,
  .Trailer:focus {
    width: 100% !important;
    //height: auto !important;
    // max-height: 200px !important;
  }
  .fixedWidthContainer {
    min-height: 220px !important;
  }
}
@media only screen and (min-width: 848px) and (max-width: 928px) {
  .cotd .workoutPlayer .Trailer {
    max-height: 470px !important;
    height: auto !important;
  }
  .Trailer,
  .thumbnail,
  .Trailer:focus {
    width: 100% !important;
    //height: auto !important;
    // max-height: 230px !important;
  }
  .fixedWidthContainer {
    min-height: 250px !important;
  }
}
@media only screen and (min-width: 928px) and (max-width: 1024px) {
  .cotd .workoutPlayer .Trailer {
    max-height: 530px !important;
    height: auto !important;
  }
  .Trailer,
  .thumbnail,
  .Trailer:focus {
    width: 100% !important;
    //height: auto !important;
    // max-height: 260px !important;
  }
  .fixedWidthContainer {
    min-height: 275px !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1100px) {
  .cotd .workoutPlayer .Trailer {
    max-height: 530px !important;
    height: auto !important;
  }
  .Trailer,
  .thumbnail,
  .Trailer:focus {
    width: 100% !important;
    //height: auto !important;
    // max-height: 260px !important;
  }
  .fixedWidthContainer {
    min-height: 295px !important;
  }
}
@media only screen and (min-width: 1101px) and (max-width: 1200px) {
  .cotd .workoutPlayer .Trailer {
    max-height: 675px !important;
    height: auto !important;
  }
  .Trailer,
  .thumbnail,
  .Trailer:focus {
    width: 100% !important;
    //height: auto !important;
    // max-height: 301px !important;
  }
  .fixedWidthContainer {
    min-height: 330px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1366px) {
  .fixedWidthContainer {
    min-height: 330px !important;
  }
}
.subscription-detail {
  display: flex !important;
  justify-content: space-between !important;
  margin-bottom: 8px;
}
@media only screen and (min-width: 200px) and (max-width: 500px) {
  .fixedWidthContainer {
    min-height: 100px !important;
  }
}
.cardBorder {
  border-radius: 8px !important;
}
.fallbackBox {
  background-color: rgba(0, 0, 0, 0.11) !important;
  animation: MuiSkeleton-keyframes-pulse 1.5s ease-in-out 0.5s infinite;
  width: 100%;
  z-index: -1;
  height: calc(100% - 4px) !important;
  position: absolute;
  bottom: 4px !important;
  transition: 0.5s ease;
  text-align: center;

  border-radius: 8px;
}
.fixedWidthContainer {
  min-height: 405px;
}
.m-l-5 {
  margin-left: 5px !important;
}
.oauth {
  .center-align {
    justify-content: center;
    gap: 2em;
  }
  .btn-default {
    width: 300px;
    //height: 64px;
    margin: 0 0 0 30px;
    padding: 0px 16px 0;
    @media only screen and (max-width: 375px) {
      width: 288px;
      margin: 0;
      padding: 0px 16px 0;
    }
    &.m-0 {
      margin: 0;
    }
  }
  .oauth-heading {
    max-width: 500px;
    margin: auto;
  }
}
.Footer {
  #langauage-selector {
    width: 212px;
    height: 19px;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    font-family: SFProDisplay-Regular;
    line-height: 1.41;
    letter-spacing: -0.3px;
    padding: 0.2em;
    color: #8e8e93;
    background-color: #fff;
    margin-top: 6px;
  }
  .MuiSvgIcon-root.MuiSelect-icon {
    color: black;
    top: calc(50% - 8px);
    font-size: 2rem;
  }
  .MuiInput-underline:active:not(.Mui-disabled):before,
  .MuiInput-underline:visited:not(.Mui-disabled):before,
  .MuiInput-underline:target:not(.Mui-disabled):before,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid #fff;
  }
}
.langauage-switcher.MuiMenuItem-root {
  font-size: 17px;
}
.langauage-switcher.MuiMenuItem-root:hover,
.langauage-switcher.Mui-selected {
  color: var(--main-primary-color) !important;
}

.liveStreamChips.bhlive .MuiChip-root {
  color: #8e8e93;
}

.bhliveLoginButtonBackgroundOverride {
  color: #ffffff !important;
}
#video-wrapper {
  .livePlayer-info {
    .container-info {
      padding: 1em;
      height: 100%;
      @media only screen and (min-width: 768px) {
        padding: 2em;
      }
    }
  }
  video.jw-video {
    border-radius: 0px;
  }
  .jw-rightclick.jw-reset.jw-open {
    display: none;
  }

  @media only screen and (min-width: 768px) {
    .jw-reset {
      border-radius: 16px !important;
    }
    video.jw-video {
      border-radius: 16px !important;
    }
  }
}
.workoutPlayer.h-218 {
  min-height: 218px;
}
#custom-slider {
  height: 5px;
  width: 100%;
  &::-ms-track,
  &::-moz-range-track,
  &:focus::-webkit-slider-runnable-track,
  &::-webkit-slider-runnable-track &::-ms-fill-lower,
  &:focus::-ms-fill-lower,
  &::-ms-fill-upper,
  &:focus::-ms-fill-upper {
    background: #ffffff;
  }
}
.d-flex {
  display: flex;
  align-items: center;
}
.font-h1 {
  font-size: 22px !important;
  font-style: normal;
  font-family: SFProDisplay-Semibold;
  font-weight: normal;
  line-height: 1;
  font-stretch: normal;
  letter-spacing: -0.8px;
  margin: 0;
}
.font-h6 {
  font-size: 13px !important;
  font-style: normal;
  font-family: SFProDisplay-Regular;
  font-weight: normal;
  line-height: 1.2;
  font-stretch: normal;
  letter-spacing: normal;
  margin: 0;
}
.font-caption {
  font-size: clamp(15px, 1vw, 1vw) !important;
  font-style: normal;
  font-family: SFProDisplay-Regular;
  font-weight: normal;
  line-height: 1.47;
  font-stretch: normal;
  letter-spacing: normal;
  margin: 0;
}
.classname {
  color: white;
  font-weight: normal;
  font-family: SFProDisplay-Semibold;
  font-size: clamp(28px, 28px, 28px) !important;
  line-height: 32px;
  letter-spacing: -0.2px;
  opacity: 1;
  //white-space: pre-line;
}
.m-b-7 {
  margin-bottom: 10px;
}
.m-t-60 {
  margin-top: 48px;
  @media (min-width: 1360px) {
    margin-top: 60px;
  }
}
.m-t-30 {
  margin-top: 36px;
  @media (min-width: 1360px) {
    margin-top: 52px;
  }
}
.m-b-15 {
  margin-bottom: 28px !important;
  @media (min-width: 768px) {
    margin-bottom: 21px !important;
  }
  @media (min-width: 1360px) {
    margin-bottom: 30px !important;
  }
}
.onDemand-description {
  @media (max-width: 767px) {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  margin-bottom: 24px !important;
  margin-top: 10px !important;
  .MuiTypography-h6 {
    font-size: 13px !important;
  }
  @media (min-width: 1360px) {
    margin-top: 16px !important;
    margin-bottom: 22px !important;
    .MuiTypography-h6 {
      font-size: 20px !important;
    }
  }
}
@media (max-width: 851px) {
  .mobileScroll ul {
    display: inline-flex !important;
    flex-wrap: nowrap !important;
  }
  .m-t-40 {
    margin-top: 40px !important;
  }
}

.content-desc {
  @media (max-width: 767px) {
    margin-top: 8px !important;
  }
  margin-top: 12px !important;
  @media (min-width: 1360px) {
    margin-top: 16px !important;
    margin-bottom: 40px !important;
  }
}
.align-item-start {
  align-items: start;
  flex-wrap: wrap;
}
.justify-content-spacebtw {
  justify-content: space-between;
}
.m-b-0 {
  margin-bottom: 0 !important;
}
.MuiButton-contained.Mui-disabled {
  color: #ffffff !important;
  background-color: #bdbdbd !important;
}
.timeDayText {
  font-family: SFProDisplay-Semibold !important;
}
.MuiTypography-overline {
  font-family: SFProDisplay-Regular !important;
}
.svg-32 {
  width: 32px;
  height: 32px;
}
.svg-24 {
  width: 24px;
  height: 24px;
}
.padding16Right {
  padding-right: 16px !important;
}
.svg-24 {
  width: 24px;
  height: 24px;
}
.m-t-32 {
  margin-top: 32px !important;
}
.play-list-notification {
  .play-list-alert {
    border-radius: 20px;
    color: #fff;
    background-color: #f44336;
    .MuiAlert-icon {
      color: #fff;
    }
  }
}
@media only screen and (max-width: 425px) {
  .ft-40 {
    font-size: 40px !important;
  }
}
@media only screen and (max-width: 810px) {
  .ft-40 {
    font-size: 40px !important;
  }
}
@media only screen and (max-width: 1370px) {
  .mt-10 {
    margin-top: 10px !important;
  }
}
@media only screen and (max-width: 2000px) {
  .mt-10 {
    margin-top: 10px !important;
  }
}
.m-t-32 {
  margin-top: 36px !important;
}

@media only screen and (min-width:767px) {
  .live-schedule-empty
  {
    margin-top:-13px!important;
  }
}
@media only screen and (min-width: 1359px) {
  .playlist-details-page {
    margin-top: 42px !important;
  }
  .live-schedule-empty
  {
    margin-top:-30px!important;
  }
}
@media only screen and (min-width: 2000px) {
  .edit-button {
    .edit-modal-button {
      margin-top: 40px !important;
    }
  }
}
#long-menu {
  div[tabindex="-1"] {
    transform-origin: unset !important;
    max-height: 100% !important;
    left: 0 !important;
    height: 100vh !important;
    width: 100% !important;
    max-width: 100%;
    padding-left: 1em;
    right: 0px;
    top: 20px !important;
  }
  li.search-items {
    margin-bottom: 1.2em;
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  }
}
.search-items {
  position: relative;
  width: 448px;
  .comp-search {
    width: 100%;
    height: 42px;
    border-radius: 8px;
    background-color: #efeff4;
    display: flex;
    justify-content: space-around;
    align-items: center;
    &.disable {
      opacity: 0.4;
      pointer-events: none;
    }
    .icon-search,
    .icon-close {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .MuiSvgIcon-root {
        width: inherit;
        height: inherit;
      }
    }
    .icon-search {
      margin-right: 0;
      flex-basis: 10%;
    }
    .icon-close {
      flex-basis: 8%;
    }
    .text-box {
      flex-basis: 85%;
      .MuiInputBase-root {
        width: 100%;
      }
      input[type="text"] {
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.3px;
        font-family: SFProDisplay-Regular;
      }
    }
    @media (min-width: 710px) and (max-width: 1024px) {
      height: 40px;
    }
  }
  .error-msg {
    position: absolute;
    margin-top: 0.1em;
    font-family: SFProDisplay-Regular;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.87;
    letter-spacing: normal;
    color: #8e8e93;
    text-align: left;
    @media (max-width: 1024px) {
      font-size: 12px;
      margin-top: 0.2em;
    }
    @media (max-width: 720px) {
      position: fixed;
    }
  }
}
@media only screen and (max-width: 767px) {
  .class-details-title,
  .item-info-title {
    display: -webkit-box !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
  .live-schedule-empty
   {
    margin-top:-10px!important;
   }
}
